
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { ContratoFornecedor } from '@/core/models/projeto';
import { ContratoFornecedorService, MedicaoService } from '@/core/services/projeto';

@Component 
export default class ListaContrato extends mixins(Vue,ListPage) {
  service = new ContratoFornecedorService();
  item = new ContratoFornecedor(); 

  titulo: string = 'Contratos de Prestação de Serviços';
  subTitulo: string = 'Contratos em execução';
  loading: boolean = false;

  dialogGerenciar: boolean = false;
  dialogNovoContrato: boolean = false
  dialogGerenciarMedicao: boolean = false
  dialogMedicao: boolean = false;

  options: any = {
    itemsPerPage: 15
  };  

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Número', value: 'numero' },
    { text: 'Contratante', value: 'empresa.nome' },
    { text: 'Prestador', value: 'fornecedor.nome' },
    { text: 'Empreendimento', value: 'empreendimento.nome' } ,
    { text: 'Total Unidades', value: 'quantidade' } ,
    { text: 'Data de Início', value: 'prazoInicio' },
    { text: 'Data de Conclusão', value: 'prazoFim' }, 
    
  ];

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns,  } = this.options;
    this.loading = true;
   
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, 'Fornecedor/Nome, Empresa/Nome, Empreendimento/Nome, Itens/Quantidade', 'Id, Numero, prazoInicio, prazoFim', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      }, 
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new ContratoFornecedor;
    this.dialogNovoContrato = true;
  } 
 
  Detalhar(item:ContratoFornecedor){
    this.service.ObterPorId(item.id,'Empresa, Itens, Itens.Etapa, Tipo, Observacoes, Documentos, Fornecedor, Empreendimento, Empreendimento.Grupos, Empreendimento.Grupos.Unidades, Fornecedor.Endereco.Estado, Fornecedor.Endereco.Municipio').then(
      res => {
        this.item = res.data
        this.dialogGerenciar = true;
      },
      err => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }

  Editar(item: ContratoFornecedor) {
    this.service.ObterPorId(item.id, 'Itens, Observacoes, Documentos' ).then(
      res => {
        this.item = new ContratoFornecedor(res.data); 
        this.dialogNovoContrato = true;
      },
      err => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }
 
  GerenciarMedicao(item){
    let filter = {contratoFornecedorId:item.id}

    new MedicaoService().ObterMedicoes(-1, -1, [],[],'',[],filter, '' , '', 'Execucoes,ContratoFornecedor.Fornecedor, ContratoFornecedor.Empreendimento').then(res => {
      this.item = res.data;
      this.dialogGerenciarMedicao = true;
    }, 
    err => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }
  

   NovaMedicao(item){
    this.service.ObterPorId(item.id,'Itens, Itens.Etapa, Empreendimento.Grupos.Unidades').then(
      res => {
        this.item = res.data;
         this.dialogMedicao = true;
      },
      err => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }

  Excluir(item: ContratoFornecedor) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            } 
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  } 
}

